import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener('body:refresh', this.refresh);
  }

  disconnect() {
    document.removeEventListener('body:refresh', this.refresh);
  }

  hideModal(event) {
    // event.preventDefault(); // this stops the delete from happening
    document.dispatchEvent(new Event('modal:hide'));
  }

  showModal({params}) {
    document.dispatchEvent(new CustomEvent('modal:show', {detail: params}));
  }

  showOverlay(event) {
    document.dispatchEvent(new Event('overlay:show'));
  }  

  hideOverlay(event) {
    document.dispatchEvent(new Event('overlay:hide'));
  }    

  loading(event) {
    document.body.classList.add('loading');
  }

  inboxToggle(event) {
    document.dispatchEvent(new Event('inbox:toggle'));
  }

  refresh(event) {
    // Turbo.renderStreamMessage("<turbo-stream action='refresh'></turbo-stream>")
    Turbo.visit(window.location, {action: 'replace'});
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

 }
