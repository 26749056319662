import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoSubmit: Boolean, default: false }

  connect() {
    console.log("text area controller connected")
    this.element.addEventListener('input', this.calculateHeight.bind(this));
    this.element.dispatchEvent(new Event('input'));
    this.element.addEventListener('keydown', this.handleKeyBinds.bind(this));
  }

  calculateHeight(event) {
    // Reset the height to the default to calculate the new height
    event.target.style.height = 'auto';
    // Set the height to the scroll height to accommodate the text
    event.target.style.height = (event.target.scrollHeight + 2) + 'px';
  
    // Trigger input event to set initial height
  }

  handleKeyBinds(event) {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey || event.altKey)) {
      if (this.autoSubmitValue) {
        event.preventDefault();
        this.element.form.requestSubmit();
      }
    }
  }

}