import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  static targets = ["form", "input", "submit"]

  static values = {
    open: { type: Boolean, default: false }
  }

  connect() {
    document.addEventListener('modal:show', this.show);
    document.addEventListener('modal:hide', this.hide);
    window.addEventListener('popstate', this.handlePopstate.bind(this));
    console.log('modal open value', this.openValue)
    if (this.openValue) {
      this.show()
    }
  }

  disconnect() {
    document.removeEventListener('modal:show', this.show);
    document.removeEventListener('modal:hide', this.hide);
    window.removeEventListener('popstate', this.handlePopstate);
  }

  handlePopstate(event) {
    this.hide(false);
  }

  formTargetConnected(form) {
    form.addEventListener("turbo:submit-end", this.submitEnd)
    form.addEventListener("submit", e => {
      /* This is to prevent the browser from focussing on the next form on the page if they hit enter */
      document.activeElement.blur()
      form.requestSubmit()
    })
    form.focus()
  }

  formTargetDisconnected(form) {
    form.removeEventListener("turbo:submit-end", this.submitEnd)
  }

  inputTargetConnected(input) {
    input.addEventListener("change", this.fetchForm.bind(this));
    input.addEventListener("click", this.fetchForm.bind(this));
  }

  enableSubmit() {
    this.submitTargets.forEach((submit) => {
      submit.removeAttribute("disabled")
    })
  }

  submit(event) {
    if (event.params && event.params.submitType) {
      var submitType = document.createElement("input");
      submitType.setAttribute("type", "hidden");
      submitType.setAttribute("name", "submit_type");
      submitType.setAttribute("value", event.params.submitType);
      this.formTarget.appendChild(submitType);
    }

    event.preventDefault();
    if (this.hasFormTarget) {
      document.activeElement.blur()
      this.formTarget.requestSubmit()
    }
  }

  submitEnd = (e) => {
    const fetchResponse = e.detail.fetchResponse
    if(fetchResponse) {
      if(e.detail.success) {
        //if the response header contains a header called modal, then we will not hide the modal
        if(fetchResponse.response.headers.get("modal_redirect_to")) {
          // Turbo.visit(fetchResponse.headers.get("modal_redirect_to"))
          let frame = document.querySelector("turbo-frame#modal")
          frame.src = fetchResponse.response.headers.get("modal_redirect_to")
        } else {
          if(!fetchResponse.response.redirected) {
            document.dispatchEvent(new Event('modal:hide')); // hide the modal via event to allow for other controllers to be notified 
          }
        }
      }
    }
  }

  show = (event) => {
    // document.getElementById("modal").innerHTML = ""
    this.element.classList.add("active")
    if (event && event.detail?.modal) {
      this.element.classList.add(event.detail.modal)
    }
    history.pushState({ modalOpen: true }, '', '');
    lock(document.body)
    document.dispatchEvent(new Event('document:lock'));
  }

  hide = (enableHistory=true) => {
    if (this.element.classList.contains("active")) {
      this.element.classList.remove("blur");
      this.element.classList.remove("active");
      if (enableHistory) {
        history.back()
      }

      this.removeModalParamFromUrl()

      setTimeout(() => {
        unlock(document.body);
        document.dispatchEvent(new Event('document:unlock'));
        document.getElementById("modal").innerHTML = "";
        document.getElementById("modal-side").innerHTML = "";
        this.element.classList.remove("large");
      }, 500)
    }
  }

  removeModalParamFromUrl() {
    // Remove only the modal parameter from the URL, keeping other parameters
    // the modal parameter will be used to trigger a modal popup (i.e. mentions inbox)
    if (window.location.search.includes("modal")) {
      setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        params.delete("modal");
        window.history.replaceState({}, document.title, `${location.pathname}?${params}`);
      }, 0)
    } 
  }

  /* Fetch form */
  async fetchForm() {
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    return `${document.getElementById('modal').getAttribute('src')}?${this.queryString()}`;
  }

  url() {
    return window.location.href;
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      params.append(name, value);
    }

    return params.toString();
  }

  side = () => {
    // If the modal has a submit button, we disable auto hide, but otherwise we allow it to hide
    const submit = this.element.querySelector(".modal-actions [type='submit']")
    if (submit) {
      return false
    }
    this.hide();
  }

}