import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "form", "input", "submit"]

  connect() {
    console.log("comment controller connected")
    this.inputTarget.addEventListener('input', this.check.bind(this))
    this.check();
  }

  check(event) {
    if (this.inputTarget.value.length == 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }

}