import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    console.log("mentions controller connected", this.fieldTarget)
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      // menuItemLimit: 25,
      values: this.fetchUsers,
      // values: [
      //   {key: 'Phil Heartman', value: 'pheartman'},
      //   {key: 'Gordon Ramsey', value: 'gramsey'},
      //   {key: 'Jacob Duursma', value: 'jacobduursma'}
      // ],      
      trigger: '@', // Add this line to specify the trigger character
      menuShowMinLength: 0 // Add this to show menu after typing @
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    console.log("tribute initialized")
  }

  fetchUsers(text, callback) {
    console.log("fetching users", text)
    fetch(`/users/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

  replaced(e) {
    console.log("replaced", e)
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    console.log("paste html", html, startPos, endPos)
    let position = this.editor.getPosition()
    // this.editor.setSelectedRange([position - endPos, position])
    this.editor.setSelectedRange([position  - (endPos - startPos), position])
    this.editor.deleteInDirection("backward")
  }
}