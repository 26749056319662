// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./web_components"
import { set } from "lodash"
import "trix"
import "@rails/actiontext"

// import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

document.addEventListener("turbo:before-stream-render", ((event) => {
  // Redirect to a new location
  const fallbackToDefaultActions = event.detail.render
  event.detail.render = function (streamElement) {
    if (streamElement.action == "refresh") {
      Turbo.visit(window.location, {action: 'replace'});
    } else if (streamElement.action == "redirect") {
      // window.location.href = streamElement.getAttribute('location')
      const delay = streamElement.getAttribute('delay') || 0
      setTimeout(() => {
        Turbo.visit(streamElement.getAttribute('location'))
      }, delay)
    } else {
      fallbackToDefaultActions(streamElement)
    }
  }

  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  // if (event.target.firstElementChild instanceof HTMLTemplateElement) {
  //   var enterAnimationClass = event.target.templateContent.firstElementChild.dataset.streamEnterClass
  //   if (enterAnimationClass) {
  //     event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass)
  //   }
  // }

  // Add a class to an element we are about to remove from the page
  // as defined by its "data-stream-exit-class"
  if (event.target.action == "remove") {
    var elementToRemove = document.getElementById(event.target.target)
    if (elementToRemove) {
      var streamExitClass = elementToRemove.dataset.streamExitClass
      if (streamExitClass) {
        // Intercept the removal of the element
        event.preventDefault()
        elementToRemove.classList.add(streamExitClass)
        // Wait for its animation to end before removing the element 
        // elementToRemove.addEventListener("animationend", function(e) {
        //   elementToRemove.remove()
        // })
        // The above doesn't work if there are multiple animations with different durations, so we'll just assume
        // animations won't take longer than 5 seconds and if an exit-class has been defined it will contain
        // ainmations to 'hide' the element from the page for the user before the timeout.
        setTimeout(function () {
          elementToRemove.remove()
        }, 1000)
      }
    }
  }

  // Add a class to an element we are about to remove from the page
  // as defined by its "data-stream-entry-class"
  if (event.target.action == "update" || event.target.action == "insert" || event.target.action == "replace") {
    var elementToAdd = document.getElementById(event.target.target)
    if (elementToAdd) {
      var streamEntryClass = elementToAdd.dataset.streamEntryClass
      if (streamEntryClass) {
        // Intercept the removal of the element
        // event.preventDefault()
        elementToAdd.classList.add(streamEntryClass)
        // Wait for its animation to end before removing the element 
        // elementToRemove.addEventListener("animationend", function(e) {
        //   elementToRemove.remove()
        // })
        // The above doesn't work if there are multiple animations with different durations, so we'll just assume
        // animations won't take longer than 5 seconds and if an exit-class has been defined it will contain
        // ainmations to 'hide' the element from the page for the user before the timeout.
        // setTimeout(function () {
        //   elementToAdd.remove()
        // }, 1000)
      }
    }
  }


}))
