import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("inbox controller connected", this.element)
    this.show = false;
    document.addEventListener('inbox:toggle', this.toggle.bind(this));
  }

  toggle(event) {
    this.show = !this.show;
    if (this.show) {
      this.element.classList.add("active");
    } else {
      this.element.classList.remove("active");
    }
  }

  hide(event) {
    if (event.currentTarget === this.element) {
      this.show = false;
      this.element.classList.remove("active");
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

}